import React, { Component } from 'react'
import { Link } from 'gatsby'
import Video from 'react-player'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-solutions'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import SEO from 'components/seo'

import {
  BannerSolucao,
  TituloPrimario,
  TituloSecundario,
  TextParagrafo,
  SectionVideo,
  SectionPraticidade,
  SectionRotinaFinanceiras,
} from '../../Style/solucoes'

import { Container, TitleBanner } from '../../Style/global'

import IlustraControleFinanceiro from 'images/ilustra-controle-financeiro.svg'
import IconeAuditoriaCaixa from 'images/icone-auditoria-caixa.svg'
import IconeConciliacaoBancaria from 'images/icone-conciliacao-bancaria.svg'
import IconeVencimentoCartoes from 'images/icone-vencimento-cartoes.svg'
import IconeContasPagar from 'images/icone-conta-pagar.svg'
import IlustracaoRotinasFinanceiras from 'images/ilustracao-rotinas-financeiras.svg'
import IconeRecebimentoFarmacia from 'images/icone-recebimento-farmacia.svg'
import IconeFechamentoConvenios from 'images/icone-fechamento-convenios.svg'
import IconeCobrancas from 'images/icone-cobrancas.svg'

// Sass Global
import 'sass/main.scss'

class PageControleFinanceiro extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Controle Financeiro" />

        <Header />

        <Navigation from={this.props.location.pathname} />

        <BannerSolucao className="banner-controle-estoque">
          <Container className="flex">
            <div className="texto">
              <TitleBanner>controle financeiro</TitleBanner>
              <h1>Torne o controle financeiro da sua farmácia ainda mais fácil</h1>
              <p>
                Com o INOVAFARMA você acompanha as movimentações de
                <br /> entrada e saída de forma assertiva
              </p>
              <Link to="/sobre/contato">Solicite uma demonstração</Link>
            </div>
            <img src={IlustraControleFinanceiro} alt="Tela sistema Inovafarma gráficos, listagem e botões" className="ilustra" />
          </Container>
        </BannerSolucao>

        <SectionPraticidade>
          <Container>
            <TituloPrimario>Veja como é fácil gerenciar seu financeiro </TituloPrimario>
            <TextParagrafo>Simplifique sua rotina com as ferramentas do sistema INOVAFARMA.</TextParagrafo>
            <div className="all-cards">
              <div className="card">
                <div className="img">
                  <img src={IconeAuditoriaCaixa} alt="Icone auditora de caixa" />
                </div>
                <h4>Auditora de caixa</h4>
                <p>Confira os valores dos caixas operacionais e garanta a integridade da sua rotina financeira.</p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeConciliacaoBancaria} alt="Icone conciliação bancária" />
                </div>
                <h4>Conciliação bancária</h4>
                <p>Concilie lançamentos do sistema com sua conta bancária de maneira simples e descomplicada.</p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeVencimentoCartoes} alt="Icone vencimento de cartões" />
                </div>
                <h4>Vencimento de cartões</h4>
                <p>Acompanhe os lançamentos e recebimentos dos cartões da sua farmácia e garanta mais controle das operações.</p>
              </div>
              <div className="card">
                <div className="img">
                  <img src={IconeContasPagar} alt="Icone contas a pagar/receber" />
                </div>
                <h4>Contas a pagar/receber</h4>
                <p>Programe o processo de pagamento e recebimento e mantenha sua organização financeira em dia.</p>
              </div>
            </div>
          </Container>
        </SectionPraticidade>

        <SectionRotinaFinanceiras>
          <Container>
            <TituloPrimario>
              Tenha mais segurança nas
              <br /> suas rotinas financeiras
            </TituloPrimario>
            <div className="info">
              <img
                src={IlustracaoRotinasFinanceiras}
                alt="Tela sistema Inovafarma consulta de vendas PBM e Financeiro"
                className="ilustra"
              />
              <ul>
                <li>
                  <img src={IconeRecebimentoFarmacia} alt="Icone farmácia" className="icone" />
                  <TituloSecundario>
                    Recebimento de
                    <br /> farmácia popular
                  </TituloSecundario>
                  <p>Controle os recebimentos do programa Farmácia Popular</p>
                </li>
                <li>
                  <img src={IconeFechamentoConvenios} alt="Icone grafico" className="icone" />
                  <TituloSecundario>Fechamento de convênios</TituloSecundario>
                  <p>Torne ainda mais fácil o gerenciamento dos seus convênios.</p>
                </li>
                <li>
                  <img src={IconeCobrancas} alt="Icone carteira" className="icone" />
                  <TituloSecundario>Cobranças</TituloSecundario>
                  <p>Organize a rotina de cobrança da farmácia e reduza a taxa de inadimplência.</p>
                </li>
              </ul>
            </div>
          </Container>
        </SectionRotinaFinanceiras>

        <SectionVideo className="bg-gray">
          <Container>
            <TituloPrimario>Veja como é simples fazer a gestão de compra e estoque da sua farmácia</TituloPrimario>
            <Video className="video video-controle-financeiro" url="https://www.youtube.com/watch?v=FetW2Wtr06M" light playing />
          </Container>
        </SectionVideo>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default PageControleFinanceiro
